<template>
  <div :class="['div-input', className]">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <label v-if="textFloat" class="title"
        >{{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
        <span v-if="textWarning" class="text-warning-message ml-3">{{
          textWarning
        }}</span>
        <span v-if="textDesc" class="text-desc-message">{{ textDesc }}</span>
        <slot name="text"></slot>
      </label>
      <div :class="[{ 'has-icon': faIcon || hasIcon }]">
        <div class="warp-input">
          <input
            :id="id"
            :class="['custom-input']"
            :type="type == 'password' ? passwordType : type"
            :placeholder="placeholder"
            :name="name"
            :required="required"
            :value="value"
            :size="size"
            @input="$emit('input', $event.target.value)"
            @change="onDataChange"
            @keyup="onKeyup"
            @keypress="onKeypress"
            @keydown="$emit('onKeydown', $event)"
            @click="$emit('focus', $event)"
            @blur="$emit('blur', $event)"
            :disabled="disabled"
          />
          <div
            class="auto-complete-customs"
            v-if="
              optionsAutoComplete &&
              optionsAutoComplete.length &&
              isDisplayAutoComplete
            "
          >
            <div
              v-for="(item, i) in optionsAutoComplete"
              :key="i"
              @click="handleSelectAutoComplete(item)"
            >
              {{ item[textField] }}
            </div>
          </div>
        </div>
        <div v-if="isShowPassword" :class="['button-eye']">
          <font-awesome-icon
            v-if="passwordType == 'password'"
            @click="handleShowHidePassword"
            :icon="['fas', 'eye-slash']"
            class="pointer"
          />
          <font-awesome-icon
            v-else
            @click="handleShowHidePassword"
            :icon="['fas', 'eye']"
            class="pointer"
          />
        </div>
        <div v-if="faIcon" :class="['fa-icon-custom', 'pointer']">
          <font-awesome-icon
            @click="$emit('onClickIcon', value)"
            :icon="faIcon"
            class="pointer"
          />
        </div>
        <slot name="icon">
          <div @click="$emit('onClickIcon', value)"></div
        ></slot>
      </div>
    </div>
    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <span v-if="detail" class="text-desc text-wrap">{{ detail }}</span>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">กรุณากรอกข้อมูล</span>
      <span class="text-error" v-else-if="v.minLength == false"
        >กรุณากรอกตัวอักษรอย่างต่ำ {{ v.$params.minLength.min }} ตัวอักษร
      </span>
      <span class="text-error" v-else-if="v.email == false"
        >กรุณากรอกอีเมลให้ถูกต้อง (เช่น email@example.com).</span
      >
      <span class="text-error" v-else-if="v.integer == false"
        >กรุณากรอกตัวเลขเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.maxLength == false"
        >กรุณากรอกตัวอักษรมากสุด {{ v.$params.maxLength.max }} ตัวอักษร
      </span>
      <span class="text-error" v-else-if="v.decimal == false"
        >กรุณากรอกตัวเลขเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.numeric == false"
        >กรุณากรอกตัวเลขเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.minValue == false"
        >กรุณากรอกตัวเลขมากกว่าหรือเท่ากับ {{ v.$params.minValue.min || 0 }}
      </span>
      <span class="text-error" v-else-if="v.minValueZero == false"
        >กรุณากรอกตัวเลขไม่น้อยกว่า 0
      </span>
      <span class="text-error d" v-else-if="v.sameAsPassword == false"
        >รหัสผ่านต้องสัมพันธ์กัน
      </span>
      <span class="text-error" v-else-if="v.validateMaxLength == false"
        >กรุณากรอกตัวอักษรมากสุด {{ maxLength }} ตัวอักษร
      </span>
      <span class="text-error" v-else-if="v.alpha == false"
        >กรุณากรอกตัวอักษรเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.alphaNum == false"
        >กรุณากรอกตัวอักษรหรือตัวเลขเท่านั้น
      </span>
      <span class="text-error" v-else-if="v.password == false"
        >รหัสผ่านจะต้องประกอบด้วยตัวอักษร a-z และ 1-9 ควรมีความยาวไม่ต่ำกว่า 8
        ตัวอักษร</span
      >
      <span class="text-error" v-else-if="v.handleMaxTransfer == false"
        >จำนวนที่ต้องการโอนต้องน้อยกว่าหรือเท่ากับจำนวนปัจจุบัน</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: false,
      type: String
    },
    textFloat: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },
    type: {
      required: false,
      type: String,
      default: 'text'
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    className: {
      required: false,
      type: String
    },
    img: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    icon: {
      required: false,
      type: String
    },
    maxLength: {
      required: false,
      type: Number
    },
    isShowPassword: {
      required: false,
      type: Boolean
    },
    textWarning: {
      required: false,
      type: String
    },
    textDesc: {
      required: false,
      type: String
    },
    faIcon: {
      required: false,
      type: String
    },
    hasIcon: {
      required: false,
      type: Boolean,
      default: false
    },
    optionsAutoComplete: {
      required: false,
      type: [Array, Object],
      default: null
    },
    textField: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      passwordType: 'password',
      isDisplayAutoComplete: false
    };
  },
  methods: {
    onDataChange(event) {
      this.$emit('onDataChange', event.target.value);
    },
    handleShowHidePassword() {
      if (this.passwordType == 'password') {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },
    onKeypress(event) {
      this.$emit('onKeypress', event);
      if (this.optionsAutoComplete) this.isDisplayAutoComplete = true;
    },
    onKeyup(event) {
      if (event.keyCode === 13) {
        this.$emit('onEnter', event.target.value);
      }

      this.$emit('onKeyup', event);
    },
    handleSelectAutoComplete(item) {
      this.isDisplayAutoComplete = false;
      this.$emit('selectAutoComplete', item);
    }
  }
};
</script>

<style scoped>
.div-input {
  position: relative;
  white-space: nowrap;
}
.div-input .title {
  color: #333 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100%;
  position: relative;
}
.input-custom {
  padding: 0px;
}
.input-border-0 .input-custom input {
  border: 0 !important;
}
.input-custom .has-icon {
  width: 100%;
  display: flex;
  align-items: center;
}

.warp-input {
  display: block;
  border: none;
  width: 100%;
  /* width: calc(100% - 32px - 10px); */
  position: relative;
}
.rounded .input-custom .has-icon .warp-input {
  width: calc(100% - 32px - 10px);
}
.rounded .input-custom .has-icon input {
  border-radius: 20px !important;
  border: none;
  padding: 2px 10px;
  text-align: center;
  border: 1px solid transparent !important;
  width: 100%;
}
.rounded .input-custom .has-icon input:focus {
  border: 1px solid var(--primary-color) !important;
}
.input-custom input {
  color: #404040;
  /* background-color: white; */
  border: 1px solid #bcbcbc !important;
  border-radius: 10px;
  padding: 8px 10px;
  height: 38.66px;
}
.input-custom input[size='lg'] {
  height: 55px;
}
.input-custom input:focus {
  border: 1px solid var(--primary-color) !important;
}
.input-custom.error input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.warp-input {
  display: block;
  border: none;
  width: 100%;
  position: relative;
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #9b9b9b;
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.d-grid {
  display: grid;
}
.button-eye {
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 6;
}
.text-warning-message {
  color: #bebebe;
  font-size: 12px;
}
.text-desc-message {
  color: #999;
  text-align: right;
  position: absolute;
  right: 0;
  font-weight: normal;
}
.fa-icon-custom {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}
.fa-icon-custom svg {
  color: white !important;
  font-size: 18px;
}

.auto-complete-customs {
  background: white;
  position: absolute;
  width: 100%;
  top: 32px;
  z-index: 1;
  border: 1px solid #bcbcbc !important;
  max-height: 150px;
  overflow-y: auto;
}
.auto-complete-customs > div {
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}
.auto-complete-customs > div:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}
</style>
